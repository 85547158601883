<template>
  <MeestReturnsListUser
      :countFilterParams="countFilterParams"
      :filterGetParams="filterGetParams"
      @createConsolidationMeest="createConsolidationMeest"
      @resetFilter="resetFilter"
      @showMore="showMore"
      @changeFilter="changeFilter"
      @reload="reload"
      @massThermalPrint="massThermalPrint"
  />
</template>

<script>
  import MeestReturnsListUser from "./MeestReturnsListUser/MeestReturnsListUser";
  import {routeFilter} from "@/mixins/routeFilterMixins/routeFilter";

  export default {
    name: "MeestReturnsList",
    components: {
      MeestReturnsListUser,
    },

    mixins: [routeFilter],

    data(){
      return {
        forPage: this.$store.getters.getReturnGoodsDocumentsForPage,
        page: 1,
        onePage: 1,
        filterDate: [],
        filterId: '',
        filterUserId: '',
        filterUserName: '',
        filterTrackingNumber: '',
        filterStatus: '',

        countFilterParams: 0,
        filterGetParams: {},
      }
    },

    mounted() {
      this.filter()
    },

    methods: {

      createConsolidationMeest() {
        let exportIds = this.getCheckedRows('row-name')
        if(exportIds.length === 0) return this.$router.push(this.$store.getters.GET_PATHS.reportsConsolidationMeestCreation)
        // this.openNotify('error', 'consolidationMeest_selectOrder')

        let urlIds = this.$store.getters.GET_PATHS.reportsConsolidationMeestCreation + '?id='
        exportIds.map((id, index) => {
          if(index > 0) urlIds += ','
          urlIds += id
        })

        this.$router.push(urlIds)
      },

      getFilterParam() {
        this.filterGetParams = {
          page: this.$route.query.page,
          count: this.$route.query.count,
          date: this.$route.query.date,
          userName: this.$route.query.userName,
          userId: this.$route.query.userId,

          id: this.$route.query.id,
          trackingNumber: this.$route.query.trackingNumber,
          status: this.$route.query.status,
        }
      },

      filter(next = false) {
        this.getFilterParam()

        let url

        // generate main filter if has get params in URL
        let generateMainFilter = this.generateMainFilterFromUrl(next, this)

        // generate other filter if has get params in URL
        this.generateParamsFilterFromUrl(this)

        // Generate filter URL
        url = this.generateFilterUrl(
            generateMainFilter.page,
            generateMainFilter.forPage
        )

        if (next) this.$store.commit('setNextReturnGoodsDocumentsPage', true)
        this.$store.dispatch('fetchReturnGoodsDocuments', url).then(() => {
          this.$store.commit('setNextReturnGoodsDocumentsPage', false)
          this.$store.commit('setGlobalUpdateTable', false)
        })


        this.checkCountFilter(['userName'])
      },

      generateFilterUrl(page, forPage) {
        let myQuery = this.createQueryBuilderObject({withUserNewMark: true})

        this.generateFilterQueryParams(
            myQuery,
            {
              filterId: 'ReturnDocumentId',
              filterStatus: 'byStatus',
              filterTrackingNumber: 'ReturnDocumentTrackingNumber',
            },
        )

        if (this.filterDate.length > 0) {
          let date = this.generateDateForFilter(this.filterDate, 'YYYY-MM-DD')

          myQuery.whereIn('byDeliveryDate', date)
        }

        return this.generateGetParamsFromQuery(myQuery, forPage, page)
      },

      massThermalPrint() {
        let data = []
        this.getCheckedRows('row-name').map(item => {
          data.push({
            id: item
          })
        })

        this.$store.dispatch('meestMassThermalPrint', {return_documents: data}).then((response) => {
          let data = this.getRespData(response)
          this.globalOpenPdfBase64(data?.fileContentBase64)
        })
      },

    },

  }
</script>

<style scoped>

</style>