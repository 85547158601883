<template>
  <div class="fragment">

    <div class="horiz-block" v-if="isAdmin">
      <div class="horiz-block__inner">

        <div class="filter-parcel-track">
          <div class="filter-parcel-track__item wfc"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <div class="admin-edit" @click="editTranslate(['meest_createConsolidation'])"></div>
            <div class="accept-mn-link btn-style"
                 @click="$emit('createConsolidationMeest')"
            >
              <div class="accept-mn-link__ico">
                <AcceptMNIco/>
              </div>
              {{$t('meest_createConsolidation.localization_value.value')}}
            </div>

          </div>
        </div>

      </div>
    </div>

    <div class="content-top-line position-relative"
         v-bind:class="{'mt-0': isAdmin}"
    >
      <div class="content-tabs content-tabs--separator"></div>

      <div class="content-top-line-wrap">
        <ToggleFilterButton :count="countFilterParams" @toggleFilter="$emit('toggleFilter')" :showFilter="showFilter"/>
      </div>
    </div>

    <div class="light-bg-block">
      <div class="btn-left-block"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate([
        'common_selectAll'
        ])"></div>

        <div class="btn-left-block__list">
          <div class="btn-left-block__list__i align-items-center" v-if="isAdmin">
            <DefaultCheckbox
                class="scoped-margin"
                :label="$t('common_selectAll.localization_value.value')"
                @input="(val) => $emit('selectAll', val)"
            />
          </div>
        </div>
      </div>


      <div class="btn-right-block">
        <div class="content-top-line-wrap ml-2"
             v-if="isAdmin"
             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <span class="admin-edit" @click="editTranslate([
          'common_termalPrint',
        ])"></span>
          <TableGeadButton
              :value="$t('common_termalPrint.localization_value.value')"
              :ico="'print'"
              @click.native="$emit('massThermalPrint')"
          />
        </div>

        <router-link class="ml-auto" :to="$store.getters.GET_PATHS.ordersMeestReturnsCreation">
          <MainButton class="btn-fit-content"
                      :value="$t('shops_addNew.localization_value.value')"
                      :ico="'ico'"
          >
            <template slot="ico">
              <PlusIconSVG class="btn-icon-plus"/>
            </template>
          </MainButton>
        </router-link>
      </div>

    </div>
  </div>
</template>

<script>
import ToggleFilterButton from "../../../../../../UI/buttons/ToggleFilterButton/ToggleFilterButton";
import DefaultCheckbox from "@/components/UI/checkboxes/DefaultCheckbox/DefaultCheckbox";
import AcceptMNIco from "../../../../../../../../public/img/UI-group/accept-mn-icon.svg?inline";
import MainButton from "@/components/UI/buttons/MainButton/MainButton";
import PlusIconSVG from '../../../../../../../../public/img/UI-group/plus-icon.svg?inline'
import TableGeadButton from "@/components/coreComponents/TableComponents/TableHeadButton/TableHeadButton";


export default {
  name: "MeestReturnsHead",
  components: {
    TableGeadButton,
    MainButton,
    DefaultCheckbox,
    ToggleFilterButton,
    AcceptMNIco,
    PlusIconSVG,
  },

  props: {
    selectedNow: Boolean,
    showFilter: Boolean,
    countFilterParams: Number,
  },

  data() {
    return {}
  },

  methods: {}
}
</script>

<style scoped lang="scss">

@import "../../../../../../../scss/mixins/mixins";
@import "../../../../../../../scss/colors";

.horiz-block{
  padding-top: 24px;

  &__inner{
    display: flex;
    align-items: center;
    position: relative;

    @include for-1120{
      align-items: flex-start;
    }

    @include for-680{
      flex-direction: column;
    }
  }

  &__btn{
    @include from-680 {
      margin-left: auto;
    }

    button{
      font-size: 16px;
      line-height: 19px;
      min-width: 110px;
      height: 40px;

      @include for-680 {
        margin-left: 0!important;
      }
    }
  }
}

.accept-mn-link{
  padding: 8px;
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  color: $accent;
  transition: .15s;

  &:hover{
    opacity: .7;
  }

  &__ico{
    margin-right: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

</style>

