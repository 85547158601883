<template>
  <div class="fragment">
    <template v-if="$store.getters.getReturnGoodsDocuments.length > 0 && $store.getters.getReturnGoodsDocumentsLoading !== true">
      <div class="custom-row pt-2">
        <div class="custom-col custom-col--20 custom-col--xs-100 custom-col--md-50 custom-col--xl-33"
             v-for="(item, index) in $store.getters.getReturnGoodsDocuments" :key="index">
          <div class="case-cart">
            <div class="case-cart__inner">
              <div class="case-cart__head">

                <div class="d-flex">
                  <DefaultCheckbox
                      v-if="!item.return_document_union && isAdmin"
                      class="mt-1"
                      :selectedNow="selectedNow"
                      :dataValue="item.id"
                      :bigCheckboxStyle="true"
                  />
                  <router-link
                      :to="$store.getters.GET_PATHS.ordersMeestReturns + '/show/' + item.id"
                      class="case-cart__title btn-style">
                    #{{ item.id }}
                  </router-link>
                </div>
              </div>

              <div class="case-cart__body">
                <div class="case-cart__columns">
                  <div class="case-cart__content-block">
                    <div class="case-cart__row custom-row p-0">

                      <div class="case-cart__col custom-col custom-col--50">
                        <div class="case-cart__label"
                             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                          <div class="admin-edit" @click="editTranslate(['apcDelcon_sendDate'])"></div>
                          {{ $t('apcDelcon_sendDate.localization_value.value') }}
                        </div>
                        <div class="case-cart__content">
                          <ValueHelper
                              :value="item.received_at"
                              :date="true"
                              :dateType="'DD MMM, YYYY'"
                          />
                        </div>
                      </div>

                      <div class="case-cart__col custom-col custom-col--50">
                        <div class="case-cart__label"
                             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                          <div class="admin-edit" @click="editTranslate(['returnGoods_shipped'])"></div>
                          {{ $t('returnGoods_shipped.localization_value.value') }}
                        </div>
                        <div class="case-cart__content">
                          <ValueHelper
                              :value="item.delivery_date"
                              :date="true"
                              :dateType="'DD MMM, YYYY'"
                          />
                        </div>
                      </div>




                      <div class="case-cart__col custom-col custom-col--50" v-if="item.return_parcel_id">
                        <div class="case-cart__label"
                             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                          <div class="admin-edit" @click="editTranslate(['returnGoods_returnedPackageID'])"></div>
                          {{$t('returnGoods_returnedPackageID.localization_value.value')}}
                        </div>
                        <div class="case-cart__content">
                          <router-link
                              class="table-link btn-style"
                              :to="$store.getters.GET_PATHS.problemsReturnGoodsUrl + '/show/' + item.return_parcel_id">
                            #{{item.return_parcel_id}}
                          </router-link>
                        </div>
                      </div>

<!--                      <div class="case-cart__col custom-col custom-col&#45;&#45;50" v-else>-->
<!--                        <div class="case-cart__label"-->
<!--                             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">-->
<!--                          <div class="admin-edit" @click="editTranslate(['consolidations_customerName'])"></div>-->
<!--                          {{$t('consolidations_customerName.localization_value.value')}}-->
<!--                        </div>-->
<!--                        <div class="case-cart__content">-->
<!--                          {{item.customer_name}}-->
<!--                        </div>-->
<!--                      </div>-->



                      <div class="case-cart__col custom-col">
                        <div class="case-cart__label"
                             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                          <div class="admin-edit" @click="editTranslate(['returnGoods_trackingNumber'])"></div>
                          {{ $t('returnGoods_trackingNumber.localization_value.value') }}
                        </div>
                        <div class="case-cart__content">
                          <ValueHelper
                              :value="item.tracking_number"
                          />
                        </div>
                      </div>
                      <div class="case-cart__col custom-col">
                        <div class="case-cart__label"
                             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                          <div class="admin-edit" @click="editTranslate(['returnGoods_meestTrackingNumber'])"></div>
                          {{ $t('returnGoods_meestTrackingNumber.localization_value.value') }}
                        </div>
                        <div class="case-cart__content">
                          <ValueHelper
                              :value="item.return_document_union"
                              :deep="'tracking_number'"
                          />
                        </div>
                      </div>
                    </div>

                    <div class="case-cart__row custom-row p-0">
                      <div class="case-cart__col custom-col">
                        <div class="case-cart__label"
                             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                          <div class="admin-edit" @click="editTranslate(['returnGoods_comment'])"></div>
                          {{ $t('returnGoods_comment.localization_value.value') }}
                        </div>
                        <div class="case-cart__content">
                          <ValueHelper
                              :value="item.comment"
                          />
                        </div>
                      </div>
                    </div>

                    <div class="case-cart__row custom-row p-0">
                      <div class="case-cart__col custom-col">
                        <div class="case-cart__label"
                             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                          <div class="admin-edit" @click="editTranslate(['returnGoods_status'])"></div>
                          {{ $t('returnGoods_status.localization_value.value') }}
                        </div>
                        <div class="case-cart__content">
                          {{ $t(`${_.find(MEEST_STATUSES_ARRAY, {value: item.status}).translation}.localization_value.value`) }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="case-cart__bottom">
                <div class="case-cart__btn"
                     v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                  <div class="admin-edit" @click="editTranslate(['common_delete'])"></div>
                    <CaseCartButton
                        v-if="item.status === MEEST_STATUSES.MEEST_STATUS_IN_PROGRESS.value"
                        class="secondary-brown"
                        :value="$t('common_delete.localization_value.value')"
                        :ico="true"
                        :typeIco="'delete-brown'"
                        @click.native="removeItem(item.id, index)"
                    >
                    </CaseCartButton>
                </div>
                <div class="case-cart__btn"
                     v-if="isAdmin || item.status === MEEST_STATUSES.MEEST_STATUS_IN_PROGRESS.value"
                     v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                  <div class="admin-edit" @click="editTranslate(['common_edit'])"></div>
                  <router-link :to="$store.getters.GET_PATHS.ordersMeestReturns + '/' + item.id">
                    <CaseCartButton
                        :value="$t('common_edit.localization_value.value')"
                    >
                    </CaseCartButton>
                  </router-link>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>

      <div class="table-bottom-btn">
        <div class="table-bottom-btn__inner">
          <ShowMore
              v-if="$store.getters.getReturnGoodsDocumentsCommonList.next_page_url !== null && $store.getters.getReturnGoodsDocuments.length > 0"
              @click.native="$emit('showMore')"
              :count="$store.getters.getReturnGoodsDocumentsCommonList.total - $store.getters.getReturnGoodsDocumentsForPage < $store.getters.getReturnGoodsDocumentsForPage ?
                  $store.getters.getReturnGoodsDocumentsCommonList.total - $store.getters.getReturnGoodsDocumentsForPage:
                  $store.getters.getReturnGoodsDocumentsForPage"
          />
        </div>
      </div>

    </template>

    <template v-if="$store.getters.getReturnGoodsDocumentsLoading === false && $store.getters.getReturnGoodsDocuments.length === 0">
      <NoResult
          :countFilterParams="countFilterParams"
          :title="$t('common_noDataYet.localization_value.value')"
          :titleWithFilter="$t('common_noResult.localization_value.value')"
          :text="$t('common_noDataYetTxt.localization_value.value')"
          :textWithFilter="$t('common_noResultSeems.localization_value.value')"
          @resetFilter="$emit('resetFilter')"
      />
    </template>

    <RequestReturnPackagePopup
        v-if="isModalReturnPackagePopup"
        :undefinedReturnPackageId="undefinedReturnPackageId"
        :requestReturnPackageId="requestReturnPackageId"
        @close="changeReturnPackagePopup(false)"
    />

    <AddUserReturnGoodsPopup
        v-if="isModalAddUserReturnGoodsPopup"
        :returnGoodsId="undefinedReturnPackageId"
        @close="changeAddUserReturnGoodsPopup(false)"
        @reload="$emit('reload')"
    />

  </div>
</template>

<script>
import CaseCartButton from "../../../../../../UI/buttons/CaseCartButton/CaseCartButton";
import RequestReturnPackagePopup from "../../../../popups/RequestReturnPackagePopup/RequestReturnPackagePopup";
import ShowMore from "@/components/coreComponents/Buttons/ShowMore/ShowMore";
import NoResult from "@/components/coreComponents/NoResult/NoResult";
import {returnGoodsMixin} from "@/mixins/returnGoodsMixins/returnGoodsMixin";
import AddUserReturnGoodsPopup
  from "@/components/modules/ReturnGoodsModule/popups/AddUserReturnGoodsPopup/AddUserReturnGoodsPopup";
import ValueHelper from "@/components/coreComponents/ValueHelper/ValueHelper";
import DefaultCheckbox from "@/components/UI/checkboxes/DefaultCheckbox/DefaultCheckbox";
import {MEEST_STATUSES, MEEST_STATUSES_ARRAY} from "@/staticData/staticVariables";

export default {
  name: "MeestReturnsList",
  components: {
    DefaultCheckbox,
    ValueHelper,
    AddUserReturnGoodsPopup,
    NoResult,
    ShowMore,
    CaseCartButton,
    RequestReturnPackagePopup,
  },

  mixins: [returnGoodsMixin],

  props: {
    countFilterParams: Number,
    selectedNow: Boolean,
  },

  data() {
    return {
      isModalReturnPackagePopup: false,
      isModalAddUserReturnGoodsPopup: false,
      undefinedReturnPackageId: null,
      requestReturnPackageId: null,
      MEEST_STATUSES_ARRAY: MEEST_STATUSES_ARRAY,
      MEEST_STATUSES: MEEST_STATUSES,
    }
  },

  methods: {

    removeItem(id, index) {
      let text = {
        title: 'common_AreYouSure',
        txt: '',
        yes: 'common_confirmDelete',
        no: 'common_confirmNo'
      }

      let confirm = () => {

        this.$store.dispatch('deleteReturnGoodsDocuments', id).then((response) => {
          if (this._.has(response, 'data') && response.status === this.$store.getters.GET_ERRORS.SUCCESS_CODE) {
            let meestReturns = this.$store.getters.getReturnGoodsDocuments
            meestReturns.splice(index, 1)

            this.openNotify('success', 'common_notificationRecordDeleted')
          } else {
            setTimeout(() => {
              this.openNotify('error', 'common_notificationUndefinedError')
            }, 200)
          }
        })

        this.$store.commit('setConfirmDeletePopup', false)
        return true
      }

      this.deleteFunc(text, confirm)

    },

    changeReturnPackagePopup(val, undefinedId = false, requestId = false) {
      this.isModalReturnPackagePopup = val
      if(undefinedId){
        this.requestReturnPackageId = null
        this.undefinedReturnPackageId = undefinedId
      }
      if(requestId){
        this.undefinedReturnPackageId = null
        this.requestReturnPackageId = requestId
      }
    },

    changeAddUserReturnGoodsPopup(val, undefinedId = false) {
      this.isModalAddUserReturnGoodsPopup = val
      if(undefinedId){
        this.undefinedReturnPackageId = undefinedId
      } else {
        this.undefinedReturnPackageId = null
      }
    },


  }
}
</script>

<style scoped>

</style>